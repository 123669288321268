import React from 'react';
import AQIDashboard from './AQIDashboard'; // Or the correct path if it's in a separate file

import './tailwind.css';


function App() {
  return <AQIDashboard />;
}

export default App;